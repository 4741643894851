<script lang="ts" setup>
useSquircleObserver()

const client = useSupabaseClient()
const loading = ref(false)
import { Button } from '@/components/ui/button'
import { Loader2 } from 'lucide-vue-next'

defineProps<{
  disabled?: boolean
}>()

const signInWithOAuth = async (provider) => {
  let options = {
    redirectTo: `${window.location.origin}/confirm`,
    scopes: provider == 'azure' ? 'email' : null
  }
  const { error } = await client.auth.signInWithOAuth({
    provider,
    options
  })
  if (error) console.log(error)
}



</script>

<template>
  <div class="grid grid-cols-1 gap-4 py-3">
    <Button @click.stop.prevent="async () => signInWithOAuth('google')" :disabled="disabled" variant="secondary"
      class="squircle-10">
      <Loader2 v-if="loading" class="w-4 h-4 mr-2 animate-spin" />
      <Icon v-else class="w-4 h-4 mr-3" name="logos:google-icon" />
      <span class="whitespace-nowrap text-[1.1em]">
        Continue with Google
      </span>
    </Button>

    <Button @click.stop.prevent="async () => signInWithOAuth('azure')" :disabled="disabled" variant="secondary"
      class="squircle-10">
      <Loader2 v-if="loading" class="w-4 h-4 mr-2 animate-spin" />
      <Icon v-else class="w-4 h-4 mr-3" name="logos:microsoft-icon" />
      <span class="whitespace-nowrap text-[1.1em]">
        Sign in with Microsoft
      </span>
    </Button>
  </div>
</template>

<style scoped></style>
